import { Injectable } from '@angular/core';
import { ProvidersStore } from './state/admin/providers.store';
import { ProvidersQuery } from './state/admin/providers.query';
import { ChangeProviderConfigurationOption, BillingClient, SetProviderConfigurationOptions } from 'api-clinician-app';
import { timeout, tap, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProviderService {

  constructor(
    private providersStore: ProvidersStore,
    private providersQuery: ProvidersQuery,
    private bilingClient: BillingClient,
  ) { }

  providersByTenantId$(tenantId: string) {
    return this.providersQuery.selectAll({ filterBy: entity => entity.PracticeTenantId === tenantId });
  }
  providerById$(providerId: string) {
    return this.providersQuery.selectEntity(providerId);
  }
  settingsById$(providerId: string) {
    return this.providersQuery.selectEntity(providerId).pipe(map(provider => provider.Options));
  }

  refreshProvider(providerId: string) {
    const request = this.bilingClient.getProvider(providerId);
    return request
      .pipe(
        timeout(10000),
        tap({
          next: provider => {
            this.providersStore.upsert(providerId, provider);
          }
        }),
        map(provider => {
          return !!provider; // truthy
        })
      );
  }

  updateOptions(providerId: string, options: { [key: string]: string }) {
    const configurationOptions: SetProviderConfigurationOptions = {
      ProviderId: providerId,
      Options: options,
      OriginatedSystem: 'ClinicianApp'
    };
    const request = this.bilingClient.setProviderConfigurationOptions(providerId, configurationOptions);
    return request
      .pipe(
        timeout(10000),
        tap({
          next: restApiResult => {
            if (restApiResult.Sucess) {
              console.warn('ProviderService.updateOption()', 'TODO: Add logic to mark the provider options as pristine again');

              this.providersStore.update(
                prov => prov.PracticeId === providerId,
                prov => ({
                  Options: options
                })
              );

            }

          }
        }),
        map(restApiResult => {
          if (!restApiResult.Sucess) {
            throw new Error(restApiResult.ResponseMessage);
          }
          return true;
        })
      );
  }

  updateOption(providerId: string, OptionName: string, OptionValue: string) {
    // console.warn('UpdateOption()', `Setting value ${OptionName} to ${OptionValue}`);
    // TODO: Add validations and pistine resetting

    const providerConfigOptionChange: ChangeProviderConfigurationOption = {
      OptionName,
      OptionValue,
      OriginatedSystem: 'ClinicianApp'
    };

    const option = OptionName;
    const value = OptionValue;

    const request = this.bilingClient.setProviderConfigurationOption(providerId, providerConfigOptionChange);
    return request
      .pipe(
        timeout(10000),
        tap({
          next: restApiResult => {
            if (restApiResult.Sucess) {
              this.providersStore.update(
                prov => prov.PracticeId === providerId,
                prov => ({
                  Options: updateMapWithValue(prov.Options, option, value)
                })
              );
            }

          }
        }),
        map(restApiResult => {
          if (!restApiResult.Sucess) {
            throw new Error(restApiResult.ResponseMessage);
          }
          return true;
        })
      );

    // setProviderConfigurationOption(practiceid: string, configurationOption:
      // ChangeProviderConfigurationOption): Observable<RestApiResultOfGuid>
    /*
    export interface ChangeProviderConfigurationOption {
      ProviderId?: string | null;
      OptionName?: string | null;
      OptionValue?: string | null;
      CorrelationIds?: string[] | null;
      OriginatedSystem?: string | null;
    }
    */
  }


}

function updateMapWithValue(objectMap: { [key: string]: string }, key: string, value: any) {
  if (!objectMap == null || key == null || value == null) {
    return objectMap;
  }

  const newMap = { ...objectMap };
  newMap[key] = value;
  return newMap;
}
